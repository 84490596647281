@font-face {
  font-family: BiGPoiNts;
  src: local(BiGPoiNts), url("./BiGPoiNts.otf") format("opentype");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcf5;
}

.beforeResponse {
  -webkit-scrollbar: none;
  /* Firefox */
  overflow: -moz-scrollbars-none;
  /* Internet Explorer, Edge */
  -ms-overflow-style: none;
}

@-moz-document url-prefix() { /* Disable scrollbar Firefox */
  html {
    scrollbar-width: none;
  }
}

.beforeResponse::-webkit-scrollbar {
  display: none;
}

.beforeResponse::-ms-scrollbar {
  display: none;
}

/* width */
.response::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.response::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eee; 
  border-radius: 5px;
}
 
/* Handle */
.response::-webkit-scrollbar-thumb {
  background: #dc8400;
  border-radius: 5px;
}

/* Handle on hover */
.response::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
